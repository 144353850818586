import * as React from "react"
import {indexHolder} from '../styles/pages.module.scss'
import Seo from '../components/utilities/Seo'

// markup
const IndexPage = () => {
  return (
    <article className={indexHolder}>
      <Seo 
        title="Eliza Shaddad"
        description="The official website"
      />
      <p>The new album "The Woman You Want"</p>
      <p><a href="https://slinky.to/TheWomanYouWant" target="_blank" rel="noreferrer">Listen now</a></p>
    </article>
  )
}

export default IndexPage
